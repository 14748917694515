<template>
  <div class="abilityDetails">

    <!-- 标题 -->
    <div class="title">我的食疗报告</div>
    <div class="list">报告列表</div>
    <div class="content" v-if="direReportDataList.length > 0">
      <div class="report" v-for="(item, index) in direReportDataList" :key="index">
        <div class="item">
          <div class="name">{{ item.UserName }}</div>
          <div class="sex">先生</div>
        </div>
        <div class="item">
          <div class="ID">ID:XBEARING001</div>
          <div class="detection">检测日期:{{ item.CreateTime }}</div>
        </div>
        <div class="symptom">
          <div>所属体制:{{ item.Physique }}</div>
          <div>改善症状:{{ item.Condition }}</div>
          <div>食养果菜汁:{{ item.FruitFormula }}</div>
          <div>食养饮料:{{ item.DrinksFormula }}</div>
        </div>
        <div class="br"></div>
        <div class="ctv">点击查看详情</div>
        <img class="go" src="../../../assets/go1.png" @click="changePages(item.FoodWayPDF)" alt="">
      </div>
    </div>
    <div class="null" v-else>
      暂时还没有数据
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GetQuestionListDataByUserId } from '../../../api'
export default {
  name: '',

  data() {
    return {
      direReportDataList: []
    }
  },
  methods: {
    getDietReportListData() {
      this.$toast.loading({
        message: '获取信息中'
      })
      console.log(this.userid, "this.userid")
      GetQuestionListDataByUserId({ userid: this.userid }).then(res => {
        const { data } = res
        if (data) {
          this.direReportDataList = data
          console.log(this.direReportDataList)
        } else {
          this.$toast.fail('信息获取失败')
        }
        this.$toast.clear()
      })
    },
    changePages(url) {
      // window.open(this.FoodWayPDF)
      if (url == '') {
        this.$toast.fail('报告内容，正在生成中')
      } else {
        window.open(url)
      }
    }
    // changePages (url) {
    //   console.log(url)
    //   // window.open(this.FoodWayPDF)
    //   window.open(url)
    // }

  },
  created() {
    const openid = this.$query('openid')
    const token = this.$query('token')
    const userid = this.$query('userid')
    token && this.$store.commit('SET_TOKEN', token)
    openid && this.$store.commit('SET_OPENID', openid)
    userid && this.$store.commit('SET_USERID', userid)
    console.log(openid, "++++++")
    console.log(token, "++++++")
    console.log(userid, "++++++")
  },
  mounted() {
    console.log(this.userid, "12345789")
    this.getDietReportListData()
  },
  computed: {
    ...mapGetters([
      'userid'
    ])
  }
}
</script>

<style lang="scss" scoped>
.abilityDetails {
  height: 100%;
  background: #f2f2f2;
  background-size: 100%;
  color: #929292;
  font-size: 23px;
  overflow-y: scroll;

  .title {
    text-align: center;
    margin: -30px 0 30px 0px;
  }

  .list {
    position: relative;
    margin: 10px 40px;
    font-size: 18px
  }

  .list::before {
    content: "";
    width: 7px;
    height: 20px;
    position: absolute;
    left: -15px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 5px;
  }

  .list::before {
    background: #FF9580;
  }

  .content {
    margin-top: 10px;

    .report {
      width: 90%;
      margin: 20px;
      height: 280px;
      background-color: #ffffff;
      border-radius: 10px;

      .item {
        display: flex;
        font-size: 15px;

        .name {
          font-size: 25px;
          margin: 20px;
        }

        .sex {
          margin: 30px -10px;
        }

        .ID {
          margin: -20px 20px;
        }

        .detection {
          margin: -22px 0;
        }

      }

      .symptom {
        padding: 5px 20px;
        font-size: 15px;

        div {
          margin-top: 8px;
        }
      }

      .br {
        border-bottom: 2px dotted;
      }

      .ctv {
        font-size: medium;
        margin: 10px 80px;
        text-align: right;
      }

      .go {
        width: 40px;
        height: 19px;
        margin: -29px 0px 0px 290px;
      }
    }
  }

  .null {
    width: 90%;
    margin: 20px;
    height: 280px;
    background-color: #ffffff;
    border-radius: 10px;
    line-height: 280px;
    text-align: center;
  }

}
</style>
